<template>
  <r-page page-title="Company">
    <template #page-header>
      <h2 class="page-title">Company</h2>
      <r-menu-tab :level="2" :showed-menus="3"></r-menu-tab>
    </template>
    <template #action-bar>
      <div class="level">
        <div class="level-left column is-2 pl-0">
          <b-autocomplete
            v-model="selectedCompany"
            field="name"
            open-on-focus
            icon-right="menu-down"
            :data="companyList"
            :loading="isFetchingCompany"
            @typing="getCompanyList"
            @select="selectCompany"
            placeholder="Search Companies"
            @infinite-scroll="getMoreCompanyList"
          >
            <template slot-scope="props">
              <p>
                {{ props.option.name }}
              </p>
            </template>
          </b-autocomplete>
        </div>
        <div class="level-right">
          <div class="filter-active role-access" v-if="roleArray.length !== 0">
            Role Access +{{ roleArray.length }}
            <span>
              <b-icon
                class="click"
                icon="close"
                custom-size="mdi-18px"
                @click.native="clearFilter('role')"
              />
            </span>
          </div>
          <div class="filter-active status" v-if="statusArray.length !== 0">
            Status +{{ statusArray.length }}
            <span>
              <b-icon
                class="click"
                icon="close"
                custom-size="mdi-18px"
                @click.native="clearFilter('status')"
              />
            </span>
          </div>
          <b-button
            icon-left="filter-outline"
            class="mx-2"
            type="is-light"
            @click.native="openFilter()"
            :disabled="!companyId"
          >
            <img
              src="../../../../public/images/filter-dot.svg"
              v-if="statusArray.length !== 0 || roleArray.length !== 0"
              class="filter-dot-button"
            />
            Filter
          </b-button>
          <GeneralFilter
            :isFilterOpen="isFilterOpen"
            :roles="roleAccess"
            :statusList="statuses"
            :id="companyId"
            @closeFilter="closeFilter"
            @filterRole="filterByRole($event)"
            @filterStatus="filterByStatus($event)"
            @filterBy="filterBy($event)"
            @loadData="loadUserManagement($event)"
            @reset="resetState()"
          ></GeneralFilter>
          <b-input
            :disabled="!companyId"
            type="is-light"
            placeholder="Search"
            icon-right="magnify"
            icon-right-clickable
            @icon-right-click="searchIconClick(search)"
            @keydown.native.enter="searchIconClick(search)"
            v-model="search"
          ></b-input>
        </div>
      </div>
    </template>
    <template #page-content>
      <div
        id="table-request-employment"
        class="table-timeoff-type table-request-personal"
      >
        <b-table
          :data="data ? data.data : []"
          :per-page="perPage"
          :loading="isUserManagementLoading"
          :total="total ? total : 0"
          @page-change="onPageChange"
          ref:table
          hoverable
          paginated
          backend-sorting
          backend-pagination
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          class="table-user-management"
          @sort="onSort"
          :sticky-header="stickyHeaders"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
        >
          <template>
            <b-table-column v-slot="props" label="No." width="5%">
              <span>{{ props.index + from }}</span>
            </b-table-column>
            <b-table-column v-slot="props" label="Name" width="20%">
              <span>{{ props.row.name }}</span>
            </b-table-column>
            <b-table-column v-slot="props" label="Role Access" width="20%">
              <span>
                {{ props.row.role ? humanize(props.row.role.name) : '-' }}
              </span>
            </b-table-column>
            <b-table-column v-slot="props" label="Status" width="20%">
              <div class="user-management-status" :class="props.row.status">
                {{ props.row.status }}
              </div>
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="join_date"
              label="Date Joined"
              sortable
              width="25%"
            >
              <span>
                {{
                  props.row.joinDate
                    ? formatDate(props.row.joinDate, 'DD MMMM YYYY')
                    : '-'
                }}
              </span>
            </b-table-column>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  No data found. Please search and select the company first.
                </p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </template>
  </r-page>
</template>

<script>
import debounce from 'lodash/debounce'
// import UserManagementFilter from '../../../components/FilterContent/UserManagementFilter.vue'
import GeneralFilter from '../../../components/FilterContent/GeneralFilter.vue'
import ApiService from '../../../services/common/api.service'
import indexingParams from '@/views/InternalAdmin/Company/mixins/indexingParams'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { formatDate } from '@/services/util'
export default {
  components: {
    // UserManagementFilter,
    GeneralFilter,
  },
  mixins: [indexingParams],
  data() {
    return {
      data: null,
      companyName: '',
      companyId: 0,
      selectedCompany: '',
      companyList: [],
      pageCompanyList: 1,
      lastPageCompanyList: 1,
      isFetchingCompany: false,

      search: '',
      isFilterOpen: false,
      roleAccess: [
        {
          title: 'Admin',
          value: 'ADMIN',
          isSelected: false,
        },
        {
          title: 'Admin Internal',
          value: 'ADMIN_INTERNAL',
          isSelected: false,
        },
        {
          title: 'Employee',
          value: 'EMPLOYEE',
          isSelected: false,
        },
      ],
      statuses: [
        {
          title: 'Joined',
          value: '1',
          isSelected: false,
        },
        {
          title: 'Pending',
          value: '0',
          isSelected: false,
        },
      ],
      roleArray: [],
      statusArray: [],
      isUserManagementLoading: false,

      defaultSortOrder: 'asc',
      sortField: 'id',
      sortOrder: 'desc',
      perPage: 10,
      page: 1,
      lastPage: 1,
      total: 0,
      from: 0,
      isPaginated: true,
      stickyHeaders: true,
    }
  },

  computed: {
    ...mapGetters({
      getUserManagement: 'companies/getCompaniesUserManagementData',
    }),
  },

  methods: {
    ...mapActions({
      actionLoadUserManagement: 'companies/fetchCompaniesUserManagementData',
    }),

    ...mapMutations({
      setUserManagement: 'companies/setUserManagementData',
    }),

    /**
     *  Function to apply filter via search
     * @param {string} evt
     */
    searchIconClick() {
      this.resetState()
    },

    /**
     *  Handle for open filter
     */
    openFilter() {
      this.isFilterOpen = true
    },

    /**
     *  Handle for close filter
     */
    closeFilter() {
      this.isFilterOpen = false
    },

    /**
     * Convert Uppercase to title case
     * @param {string} str - role name
     * @return {string} formatted title case string
     */
    humanize(str) {
      let i,
        frags = str.split('_')
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].toLowerCase()
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
      }
      return frags.join(' ')
    },

    /**
     * Handle for select company
     * @param {object} option - company data
     */
    async selectCompany(option) {
      if (option) {
        this.selectedCompany = option.name
        this.companyName = option.name
        this.companyId = option.id

        await this.loadUserManagement(this.companyId)
      }
    },

    /**
     *  Function for format date
     * @param {string} date - date value
     * @param {string} format - format type
     */
    formatDate(date, format) {
      return formatDate(date, format)
    },

    async loadUserManagement(id) {
      let params = [
        ...this.getParams({
          page: this.page,
          perPage: this.perPage,
          search: this.search,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          additionalFilters: [this.roleArray, this.statusArray],
        }),
      ]
      let response = await this.actionLoadUserManagement({
        id: id,
        parameter: params.join('&'),
      })

      if (response && response.status === 200) {
        this.data = response.data
      }
      this.lastPage = response.data.meta.last_page
      this.total = response.data.meta.total
      this.from = response.data.meta.from
    },

    getCompanyList: debounce(function (name) {
      if (this.selectedCompany !== name) {
        this.selectedCompany = name
        this.companyList = []
        this.pageCompanyList = 1
        this.lastPageCompanyList = 1
      }
      if (!name.length) {
        this.companyList = []
        this.pageCompanyList = 1
        this.lastPageCompanyList = 1
      }
      if (this.pageCompanyList > this.lastPageCompanyList) {
        return
      }

      this.isFetchingCompany = true

      ApiService.get('/api/space-roketin/admin-internal/company', {
        search: this.selectedCompany,
        page: this.pageCompanyList,
      })
        .then((response) => {
          response.data.data.forEach((item) => this.companyList.push(item))
          this.pageCompanyList++
          this.lastPageCompanyList = response.data.meta.lastPage
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          this.isFetchingCompany = false
        })
    }, 500),

    /**
     * Get more company list when auto complete scrolled
     * @return {array} listCompany - Company List
     */
    getMoreCompanyList: debounce(function () {
      this.getCompanyList(this.selectedCompany)
    }, 250),

    /**
     *  HandleClick for change filter
     *  @param {object} event - listener for change filter
     */
    filterBy(event) {
      let index = event.index
      const value =
        event.type === 'status'
          ? this.statusArray.indexOf(`filter_status[]=${event.item.value}`)
          : this.roleArray.indexOf(`filter_roles[]=${event.item.value}`)

      if (value > -1) {
        this.addFilter({ value, index, type: event.type })
      } else {
        this.removeFilter({ value: event.item.value, index, type: event.type })
      }
    },

    /**
     *  Add filter to array
     *  @param {object} param
     */
    addFilter(param) {
      const { value, index, type } = param

      if (type === 'status') {
        this.statusArray.splice(value, 1)
        this.statuses[index].isSelected = false
      } else {
        this.roleArray.splice(value, 1)
        this.roleAccess[index].isSelected = false
      }
    },

    /**
     *  Remove filter in array
     *  @param {object} param
     */
    removeFilter(param) {
      const { value, index, type } = param

      if (type === 'status') {
        this.statusArray.push(`filter_status[]=${value}`)
        this.statuses[index].isSelected = true
      } else {
        this.roleArray.push(`filter_roles[]=${value}`)
        this.roleAccess[index].isSelected = true
      }
    },

    /**
     *  Function to reset filter state
     */
    async resetState() {
      this.statusArray = []
      this.roleArray = []
      this.statuses.forEach((el) => (el.isSelected = false))
      this.roleAccess.forEach((el) => (el.isSelected = false))
      this.setUserManagement([])
      await this.loadUserManagement(this.companyId)
    },

    /**
     *  Function to sort table
     * @param {string} field - field type
     * @param {string} order - order type
     */
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    /**
     *  Function to change pagination
     * @param {number} page
     */
    onPageChange(page) {
      this.page = page
      this.loadUserManagement(this.companyId)
    },

    /**
     *  Handle for clear filter by filter type
     * @param {string} type - filter type
     */
    async clearFilter(type) {
      if (type === 'status') {
        this.statusArray = []
        this.statuses.forEach((el) => (el.isSelected = false))
      } else {
        this.roleArray = []
        this.roleAccess.forEach((el) => (el.isSelected = false))
      }
      this.setUserManagement([])
      await this.loadUserManagement(this.companyId)
    },
  },
}
</script>
